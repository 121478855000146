
import SwiperCore, { Navigation, Pagination, Autoplay, Lazy } from 'swiper/core'
import 'swiper/swiper-bundle.css'

require('../../../scss/pages/index.sass');

/**
 * Main slider
 */
(() => {
  const features = document.getElementById('features')
  const sliderWrap = features.getElementsByClassName('feature-slider__wrap')[0]

  SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])
  const slider = new SwiperCore('.feature-slider__wrap', {
    spaceBetween: 20,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    speed: 400,
    preloadImages: false,
    checkInView: true,
    lazy: {
      preloaderClass: 'lds-default'
    },
    autoplay: {
      delay: 4000
    },
    centeredSlides: false,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: false
    },
    autoHeight: true,
    on: {
      afterInit: (swiper) => {
        const wrapper = swiper.$el[0]
        const activeSlide = wrapper.getElementsByClassName('swiper-slide-active')[0]
        const slide = activeSlide.getElementsByClassName('swiper-slide')[0]
        slide.style.height = '100%'
      }
    }
  })

  slider.on('slideChangeTransitionStart', (swiper) => {
    const wrapper = swiper.$el[0]
    const activeSlide = wrapper.getElementsByClassName('swiper-slide-active')[0]
    const slide = activeSlide.getElementsByClassName('swiper-slide')[0]
    slide.style.height = '100%'
  })

  slider.on('lazyImageLoad', (swiper, slideEl, imageEl) => {
  })

  const sliderBounds = features.getBoundingClientRect()
  window.addEventListener('scroll', (e) => {
    const pos = window.pageYOffset
    if (pos > (sliderBounds.height + sliderBounds.x)) {
      features.style.height = sliderBounds.height + 'px'
      slider.autoplay.pause()
    } else {
      features.style.height = 'auto'
      slider.autoplay.run()
    }
  })
})();

/**
 * Free summary
 */
(() => {
  if (!document.getElementById('summary-free')) return
  const section = document.getElementById('summary-free')
  const item = section.getElementsByClassName('popular-item')
  const sliderContainer = document.getElementById('summary-free__slider')

  let sliderItems = 4
  if (item.length < sliderItems) {
    sliderItems = item.length
  }
  SwiperCore.use([Navigation, Pagination])
  const slider = new SwiperCore(sliderContainer, {
    spaceBetween: 20,
    slidesPerView: sliderItems,
    grabCursor: true,
    preloadImages: false,
    checkInView: true,
    lazy: {
      preloaderClass: 'lds-default'
    },
    centeredSlides: false,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2
      },
      678: {
        spaceBetween: 30,
        slidesPerView: 2
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: sliderItems
      }
    }
  })
})();
/**
 * Popular summary
 */
(() => {
  if (!document.getElementById('summary-popular')) return
  const section = document.getElementById('summary-popular')
  const item = section.getElementsByClassName('slider-item')
  const sliderContainer = document.getElementById('popular-slider')
  if (item.length === 0) return
  let sliderItems = 4
  if (item.length < sliderItems) {
    sliderItems = item.length
  }
  SwiperCore.use([Navigation, Pagination])
  const slider = new SwiperCore(sliderContainer, {
    spaceBetween: 20,
    slidesPerView: sliderItems,
    grabCursor: true,
    centeredSlides: false,
    checkInView: true,
    lazy: {
      preloaderClass: 'lds-default'
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2
      },
      678: {
        spaceBetween: 30,
        slidesPerView: 2
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: sliderItems
      }
    }
  })
})();
/**
 * Popular reviews
 */
(() => {
  if (!document.getElementById('reviews-popular')) return
  const section = document.getElementById('reviews-popular')
  const sliderContainer = document.getElementById('reviews-slider')
  const item = sliderContainer.getElementsByClassName('popular-item')
  if (item.length === 0) return
  let sliderItems = 4
  if (item.length < sliderItems) {
    sliderItems = item.length
  }
  SwiperCore.use([Navigation, Pagination])
  const slider = new SwiperCore(sliderContainer, {
    spaceBetween: 20,
    slidesPerView: sliderItems,
    checkInView: true,
    grabCursor: true,
    loop: false,
    centeredSlides: false,
    preloadImages: false,
    lazy: {
      preloaderClass: 'lds-default'
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2
      },
      678: {
        spaceBetween: 30,
        slidesPerView: 2
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: sliderItems
      }
    }
  })
})();

// top list
(() => {
  if (!document.getElementById('library')) return
  const sliderContainer = document.getElementById('cat_list-slider')
  const item = sliderContainer.getElementsByClassName('best-set__item')
  if (item.length === 0) return
  let sliderItems = 5
  if (item.length < sliderItems) {
    sliderItems = item.length
  }
  const slider = new SwiperCore(sliderContainer, {
    spaceBetween: 20,
    slidesPerView: sliderItems,
    grabCursor: true,
    loop: false,
    centeredSlides: false,
    preloadImages: false,
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      350: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2
      },
      678: {
        spaceBetween: 30,
        slidesPerView: 3
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 4
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: sliderItems
      }
    }
  })
})();

/**
 * App links metrika
 */
(() => {
  if (!document.getElementById('summary_format')) return
  const root = document.getElementById('summary_format')
  const downloadsLinks = root.getElementsByClassName('summary_format__app_links')[0]
  downloadsLinks.addEventListener('click', onLinkClick)

  function onLinkClick (e) {
    e.preventDefault()
    const link = e.target
    if (link.classList.contains('summary_format__ios')) {
      ym(56072866, 'reachGoal', 'download_ios')
    }
    if (link.classList.contains('summary_format__android')) {
      ym(56072866, 'reachGoal', 'download_android')
    }
    window.location.href = link.getAttribute('href')
  }
})()
